<template>
    <v-row class="mx-n3">
        <v-col cols="12" v-if="anything_loading">
            <v-progress-linear indeterminate></v-progress-linear>
        </v-col>
        <v-col cols="12" md="3">
            <v-card flat style="height: 100%;">
                <v-card-title>
                    Licensed In The Following States: <v-divider class="mx-5"></v-divider>
                </v-card-title>
                <div class="mx-n4 mx-md-0">
                    <v-list dense>
                        <v-list-group v-for="(lic) in activeLicenses" :key="lic.ID">
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title label>
                                        <span v-if="lic.IsResidentState == 1">
                                            <v-tooltip top primary>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <b v-bind="attrs" v-on="on"> {{ lic.State}}</b>
                                                </template>
                                                <span>Resident State</span>
                                            </v-tooltip>
                                            - #{{ lic.LicenseNumber}}
                                        </span>
                                        <span v-else>{{ lic.State}} - {{ lic.LicenseNumber}}</span>
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-icon>
                                    <v-icon x-small v-if="lic.IsResidentState == 1">fas fa-home</v-icon>
                                </v-list-item-icon>
                            </template>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <div>FROM: <b>{{ lic.LicensedOnDate ? formatDate(lic.LicensedOnDate): "" }}</b></div>
                                        <br>
                                        <div v-if="false">TO: <b>{{ lic.expires ? formatDate(lic.expires): "" }}</b></div>
                                        <br v-if="false">
                                        <div>Lines of Authority: <b>{{ getLOAs(lic.state_license_loas) }}</b></div>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-3 mb-5"></v-divider>
                        </v-list-group>
                    </v-list>
                </div>
            </v-card>
        </v-col>
        <v-col cols="12" md="6" d-flex justify-start mb-6>
            <v-card-title>
                Agent Writing Numbers <v-divider class="mx-5"></v-divider>
            </v-card-title>
            <v-card-subtitle v-if="agent.NPN">
                NPN: {{agent.NPN}}
                <v-btn class="ml-5" x-small @click="showNPNChangeTool(agent.NPN, 'personal')">
                    Edit
                </v-btn>
                <q-npn-change-tool :agent="agent" ref="NpnChange" @npnchange="triggerRefresh"></q-npn-change-tool>
            </v-card-subtitle>
            <v-card-subtitle v-else>
                <v-alert type="warning">
                    Writing numbers cannot be added until this agent has a valid NPN.
                </v-alert>
            </v-card-subtitle>
            <div class="mx-n4 mx-md-0">
                <q-agent-contracting-staff ref="PersonalContracting" :agent="agent" atype="agent" v-if="hasPermission('manage:Agent')"></q-agent-contracting-staff>
                <q-agent-contracting-agent ref="PersonalContracting" :agent="agent" atype="agent" v-else></q-agent-contracting-agent>
            </div>

            <div v-if="agent.DoingBusinessAsNPN && (hasPermission('manage:Agent') || !agent.HideDBA)">
                <v-expansion-panels accordion v-if="agent.HideDBA" class="mt-4" flat>
                    <v-expansion-panel key="0">
                        <v-expansion-panel-header>DBA is Inactive</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card-title>
                                 DBA Writing Numbers<v-divider class="mx-5"></v-divider>
                            </v-card-title>
                            <v-card-subtitle>
                                DBA: {{agent.DoingBusinessAsName}} <br />NPN: {{agent.DoingBusinessAsNPN}}
                            </v-card-subtitle>
                            <div class="mx-n4 mx-md-0">
                                <q-agent-contracting-staff ref="DBAContracting" :agent="agent" atype="agency" v-if="hasPermission('manage:Agent')"></q-agent-contracting-staff>
                                <q-agent-contracting-agent ref="DBAContracting" :agent="agent" atype="agency" v-else></q-agent-contracting-agent>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <div v-else>
                    <v-card-title>
                        DBA Writing Numbers <v-divider class="mx-5"></v-divider>
                    </v-card-title>
                    <v-card-subtitle>
                        DBA: {{agent.DoingBusinessAsName}} <br />NPN: {{agent.DoingBusinessAsNPN}}
                        <v-btn class="ml-5" x-small @click="showNPNChangeTool(agent.DoingBusinessAsNPN, 'business')">
                            Edit
                        </v-btn>
                    </v-card-subtitle>
                    <div class="mx-n4 mx-md-0">
                        <q-agent-contracting-staff ref="DBAContracting" :agent="agent" atype="agency" v-if="hasPermission('manage:Agent')"></q-agent-contracting-staff>
                        <q-agent-contracting-agent ref="DBAContracting" :agent="agent" atype="agency" v-else></q-agent-contracting-agent>
                    </div>
                </div>
            </div>
        </v-col>
        <v-col cols="12" md="3">
            <v-card flat>
                <v-card-title>
                    Additional Info <v-divider class="mx-5"></v-divider>
                </v-card-title>
                <div v-if="licensing.eno" class="mx-n4 mx-md-0">
                    <v-list v-for="(v, k) in general_info" :key="k">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>{{ v.label }}:</v-list-item-subtitle>
                                <v-list-item-title>
                                    <span v-if="licensing[v.key]"> <b>{{ licensing[v.key] }}</b></span>
                                    <span v-else><b>NA</b></span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                    </v-list>
                    <v-list>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>AML Provider:
                                </v-list-item-subtitle>
                                <v-list-item-title>
                                    <b> {{ licensing.amlProvider }} - {{ licensing.amlDate }}</b>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>
                                    E&O Carrier:
                                </v-list-item-subtitle>
                                <v-list-item-title>
                                    <b> {{ licensing.eno.carrierName ? licensing.eno.carrierName : "" }}</b>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>
                                    E&O Policy #:
                                </v-list-item-subtitle>
                                <v-list-item-title>
                                    <b> {{ licensing.eno.policyNo }}</b>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>
                                    Cert #:
                                </v-list-item-subtitle>
                                <v-list-item-title>
                                    <span v-if="licensing.eno.certificateNo"><b> {{ licensing.eno.certificateNo }}</b></span>
                                    <span v-else><b>NA</b></span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>
                                    Expires:
                                </v-list-item-subtitle>
                                <v-list-item-title>
                                    <b> {{ licensing.eno.expiresOn }}</b>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </div>
            </v-card>
        </v-col>
        <v-col cols="12">
            <div v-if="!loading && hasRole(['AgencyOwner', 'SalesRep', 'Staff', 'SuperAdmin'])">
                <q-agent-appointment-request :licensing="licensing"></q-agent-appointment-request>
            </div>
            <div v-if="!loading && hasRole(['SuperAdmin','Exec','Staff'])">
                <div>
                    <h4>Carrier Info From SureLC </h4>
                    <p>The below data indicates carries where the agent has applied, but may not be licensed. To be sure the agent can write with the carrier, please reference the writing numbers above.</p>
                </div>
                <div style="width:600px; max-width:90%;">
                    <v-text-field v-model="search" :disabled="isDemoMode" label="Search"></v-text-field>
                </div>
                <v-data-table :search="search" dense :headers="headers" :items="licensing.appointments" single-expand :expanded.sync="expanded" item-key="id" show-expand :options.sync="options">
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                            <v-row wrap class="ma-9">
                                <v-col cols="12">
                                    <strong>Products:</strong><br />
                                    <ul>
                                        <li v-for="(product) in item.products" :key="product">
                                            {{ product }}
                                        </li>
                                    </ul>
                                </v-col>
                                <v-col cols="12">
                                    <div style="width:600px; max-width:90%;">
                                        <v-simple-table dense>
                                            <tbody>
                                                <tr>
                                                    <td>Date:
                                                    </td>
                                                    <td>
                                                        {{ formatDate(item.createdDate) }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Type:
                                                    </td>
                                                    <td>
                                                        {{ item.type }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </v-simple-table>
                                    </div>
                                </v-col>
                                <v-col cols="12">
                                    <div style="width:600px; max-width:90%;">
                                        <strong> Hierarchy:</strong><br>
                                        <v-simple-table dense>
                                            <template v-slot:default>
                                                <!--
                                                <thead>

                                                    <tr class="text-left">
                                                        <th class="text-left">Label</th>
                                                        <th class="text-left">Name</th>
                                                    </tr>

                                                </thead>
                                                -->
                                                <tbody v-if="item.hierarchy">
                                                    <tr v-for="item in item.hierarchy.rows" :key="item.name" class="text-left">
                                                        <td>{{ item.label }}</td>
                                                        <td>{{ item.name }}</td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </div>
                                </v-col>
                            </v-row>
                        </td>
                    </template>
                    <template v-slot:item.commission="{ item }">
                        <div v-if="item.commission">
                            <v-btn color="primary" text v-if="item.commission.rows.length > 0 && item.carrierName" small @click="showComission(item.commission, item.carrierName)">Commission</v-btn>
                        </div>
                    </template>
                    <template v-slot:item.carrierName="{ item }">
                        <div>
                            <span>{{ item.carrierName }}<br></span>
                            <v-chip x-small color="blue" dark class="mr-1" label v-for="(st) in item.states" :key="st">
                                {{ st }}
                            </v-chip>
                        </div>
                    </template>
                </v-data-table>
            </div>
        </v-col>
        <v-dialog v-model="showCommissionPopup" width="700" max-width="90%">
            <v-card class="pa-6 text-center" min-height="">
                <h4 class="pa-4 font-weight-bold">Commission<br>
                    {{ currentCommission.carrier }}
                    <span v-if="currentCommission.category != ''"> - {{ currentCommission.category }}</span>
                </h4>
                <v-simple-table>
                    <template v-slot:default>
                        <!--
                        <thead>

                            <tr class="text-left">
                                <th class="text-left">Schedule</th>
                                <th class="text-center">Value</th>
                            </tr>

                        </thead>
                        -->
                        <tbody>
                            <tr v-for="item in currentCommission.rows" :key="item.name" class="text-left">
                                <td v-if="item.value != ''"><strong>{{ item.label }}</strong></td>
                                <td v-if="item.value != ''" class="text-left">{{ item.value }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QAgentContractingStaff from '@/components/utils/QAgentContractingStaff.vue'
import QAgentContractingAgent from '@/components/utils/QAgentContractingAgent.vue'
import QAgentAppointmentRequest from '@/components/utils/QAgentAppointmentRequest.vue'
import QNpnChangeTool from '@/components/utils/QNpnChangeTool.vue';
import moment from 'moment'


export default {
    name: "QAgentLicensingSureLc",
    props: {
        agent: Object,
    },
    data: function() { // data internal to component (not available outside)
        return {
            anything_loading: false,
            loading: false,
            state_licenses : [],
            licensing: {
                appointments: [],
                licenses: [],
            },
            search: null,
            showCommissionPopup: false,
            currentCommission: {
                rows: []
            },
            general_info: [{
                key: 'dob',
                label: 'DOB'
            }, {
                key: 'driverLic',
                label: "Driver's License #"
            }, {
                key: 'driverLicState',
                label: "Driver's License State"
            }],

            expanded: [],
            options: {
                'sortBy': ['paperworkDate'],
                'sortDesc': [true]
            }
        }
    },
    mounted: function() {
        this.getLicensing();
    },
    computed: {
        'headers': function() {
            if (this.hasRole(["Staff", "SuperAdmin", "Exec"])) {
                return [
                    { text: 'Carrier', value: 'carrierName' },
                    { text: 'Paperwork Date', value: 'paperworkDate' },
                    { text: 'Commissions', value: 'commission' }
                ]
            } else {
                return [
                    { text: 'Carrier', value: 'carrierName' },
                    { text: 'Paperwork Date', value: 'paperworkDate' },
                ]
            }
        },
        activeLicenses() {
            return this.state_licenses.filter(license => license.Status === 'Active');
        }
    },
    methods: {
        getLicensing() {
            this.loading = true;
            var g = this;
            if (this.agent.NPN == null || this.agent.NPN == "") {
                this.showWarning("This agent needs to add thier NPN number in Opt! in order to view licensing information.")
                g.loading = false;
                return
            }
            QuilityAPI.getAgentLicensing(this.agent, this.role).then(function(resp) {
                if (resp.success) {
                    g.$set(g, 'licensing', resp.data)
                    //g.setUniqueAppointments()
                    //g.licensing = resp.data;
                } else {
                    g.showError("Could not retrieve licensing data from SureLC. The data you see may be out of date");
                }
                g.loading = false;
            });

            QuilityAPI.getAgentStateLicensingStore(this.agent, this.role).then(function(resp) {
                if (resp.data) {
                    g.$set(g, 'state_licenses', resp.data)
                } else {
                    g.showError("No State Licenses are available to report.");
                }
                g.loading = false;
            });
        },
        showComission(comm, carrier) {
            this.showCommissionPopup = true;
            this.currentCommission = comm;
            this.currentCommission.carrier = carrier
        },
        setUniqueAppointments: function() {
            if (typeof this.licensing.appointments == 'undefined') {
                return [];
            }
            var apps = {};
            for (var i = 0; i < this.licensing.appointments.length; i++) {
                var a = this.licensing.appointments[i];
                if (apps[a.carrierId]) {
                    //compare dates and get the latest date
                    if (moment(apps[a.carrierId].createdDate).isAfter(a.createdDate)) {
                        apps[a.carrierId] = this.licensing.appointments[i]
                    }
                } else {
                    apps[a.carrierId] = this.licensing.appointments[i]
                }
            }
            var u_apps = [];
            var keys = Object.keys(apps);
            for (var i = 0; i < keys.length; i++) {
                u_apps.push(apps[keys[i]]);
            }
            this.$set(this.licensing, 'appointments', u_apps)
        },
        getLOAs: function(data) {
            if (typeof data == 'undefined'){
                return [];
            }
            let loas = []

            for (var i = data.length - 1; i >= 0; i--) {
                loas.push(data[i].LOA)
            }

            return loas.reverse().join(', ')
        },
        updateAnythingLoading: function() {
            this.anything_loading = this.loading || this.$refs.PersonalContracting.loading
        },
        showNPNChangeTool(npn, npntype){
            this.$refs.NpnChange.showNPNChangeTool(npn, npntype);
        },
        triggerRefresh: function(newNPN) {
            this.$emit('refresh')
            this.$emit('update:agent', { ...this.agent, NPN: newNPN })
        }
    },
    watch: {
        'loading': function() {
            this.$nextTick(this.updateAnythingLoading)
        },
        '$refs.PersonalContracting.loading': function() {
            this.$nextTick(this.updateAnythingLoading)
        }
    },
    components: {
        QAgentContractingStaff,
        QAgentContractingAgent,
        QAgentAppointmentRequest,
        QNpnChangeTool
    }
}
</script>

